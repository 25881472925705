import { Box, CircularProgress, Grid, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { CoinBalance, Token } from "../../../types/coin.type";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { UserData } from "../../../types/user.type";
import { useHistory } from "react-router-dom";
import { getUserNameByAddress, getUsersList } from "../../../api/userAPI";
import { coinSend, getTokenFromSymbolSlice } from "../../../store/slices/coinSlice";
import { getTokenWithBalanceFromMetadata } from "../../../api/coinAPI";
import toast from "react-hot-toast";
import { ContactList, SearchBar } from "../Coupon/CouponWallet";
import { BlackButton } from "../../../components/Buttons/BlackButton";
import { getMostRecentUsers } from "../../../api/notificationAPI";
import { InputField } from "../../../components/Input/InputField";
import { CosoInWalletMobile } from "./Mobile/CosoInWalletMobile";
import { Ssh } from "mdi-material-ui";

export const CosoInWallet = () => {
    const dispatch = useAppDispatch();
    const [coinBalance, setCoinBalance] = useState<number>(0);
    const [balanceLoading, setBalanceLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const user = useAppSelector((state) => state.user.currentProfile);

    useEffect(() => {
        (async () => {
            const tokenAddr = await dispatch(getTokenFromSymbolSlice('COSO'));
            if (tokenAddr != null && user?.additional_properties?.commonshoodWallet != null) {
                const balance = await getTokenWithBalanceFromMetadata(user?.additional_properties?.commonshoodWallet, tokenAddr.contractAddress, 'COSO');
                if (balance && balance.balance) {
                    setCoinBalance((balance as any).balance);
                    setBalanceLoading(false);
                } else {
                    setCoinBalance(0);
                    setBalanceLoading(false);
                }
            }
        })()
    }, [])

    return (
        <Grid container spacing={4} style={{ marginTop: '16px' }}>
            <Grid item xs={12}>
                <CosoAmountBox amount={coinBalance} balanceLoading={balanceLoading} />
            </Grid>
            {
                !isMobile ? (
                    <Grid container style={{ marginTop: '32px' }}>
                        <Grid item xs={3} >
                            <FirstColumn />
                        </Grid>
                        <Grid item xs={1}>
                            <img src="/svgs/coupons/grey_line_vertical_long.svg" />
                        </Grid>
                        <Grid item xs={6}>
                            <ThirdColumn balance={coinBalance} />
                        </Grid>
                    </Grid>
                ) : (
                    <CosoInWalletMobile />
                )
            }
        </Grid >
    )
}

const cosoAmountStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        backgroundColor: 'white',
        padding: '15px',
        border: '1px solid white',
        borderRadius: '15px',
        maxWidth: '300px',
        '@media (max-width: 600px)': {
            margin: 'auto'
        }
    },
    logo: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(1),
    },
    amountContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    amount: {
        fontSize: '2.5rem',
    }
}));

const CosoAmountBox = ({ amount, balanceLoading }: { amount: number, balanceLoading: boolean }) => {
    const classes = cosoAmountStyles();

    return (
        <Box className={classes.root}>
            <img
                src="/svgs/coso_logo_rounded.svg"
                alt="COSO Logo"
                className={classes.logo}
            />
            <Typography variant="body1">COSO</Typography>
            <Box className={classes.amountContainer}>
                {/* Height is set to 64px to match the height of the font so that the box doesn't resize after displaying the amount */}
                {
                    balanceLoading ?
                    <CircularProgress style={{height: 64, width: 64}}/>
                    :
                    <Typography variant="h6" className={classes.amount}>{amount}</Typography>
                }
            </Box>
        </Box>
    );
}

const FirstColumn = () => {
    return (
        <Grid container direction="column" spacing={7} style={{ paddingRight: '40px' }}>
            <Grid item style={{ marginTop: '3em', textAlign: 'right' }}>
                <Typography variant="h6">Scegli il destinatario</Typography>
            </Grid>
            <Grid item style={{ marginTop: '8em', textAlign: 'right' }}>
                <Typography variant="h6">Quantità?</Typography>
            </Grid>
        </Grid>
    );
}

const ThirdColumn = ({ balance }: {
    balance: number
}) => {
    const [qnt, setQnt] = useState<number>(0);
    const dispatch = useAppDispatch();
    const [contact, setContact] = useState<UserData | null>(null);

    const sendCoinsFallback = () => {
        const toastId = toast.loading('Invio in corso...');
        try {
            if (!contact) {
                toast.error('Utente non trovato');
                return;
            }
            if (qnt <= 0) {
                toast.error('Inserisci una quantità positiva');
                return;
            }
            if (qnt >= balance) {
                toast.error('Non hai abbastanza COSO');
                return;
            }
            console.log('Sending coins to ', contact, ' amount: ', qnt.toString());
            toast.promise(
                dispatch(coinSend({
                    recipient: contact,
                    amount: qnt.toString(),
                    symbol: 'COSO',
                    decimals: 2
                })),
                {
                    loading: 'Invio in corso...',
                    success: 'Invio completato!',
                    error: 'Invio fallito'
                }
            );
        } catch (e: any) {
            console.log(e.message);
            toast.error(e.message);
        } finally {
            toast.dismiss(toastId);
        }
    }

    return (
        <>
            <Grid container style={{ marginTop: '3em' }}>
                <ListContactCoso setContactFallback={(contact) => {
                    setContact(contact);
                }} />
                <Grid item xs={12} style={{ marginTop: '4em' }}>
                    <InputField
                        type={'number'}
                        value={qnt.toString()}
                        placeholder='_ _ _'
                        variant='outlined'
                        onChange={(e) => {
                            setQnt(parseInt(e.target.value));
                        }}
                        style={{ textAlign: 'center', width: '30%' }}
                        min={1}
                        max={999}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: '30px' }}>
                    <BlackButton style={{ width: '200px', color: 'white' }} onClick={sendCoinsFallback} text={'Invia'} />
                </Grid>
            </Grid>
        </>
    )
}

export const ListContactCoso = ({ setContactFallback }: { setContactFallback: (contact: UserData) => void }) => {
    const [contacts, setContacts] = useState<UserData[]>([]);
    const [recentContacts, setRecentContacts] = useState<UserData[]>([]);
    const [contactsFiltered, setContactsFiltered] = useState<UserData[]>([]);
    const [contact, setContact] = useState<UserData | null>(null);

    //Allow user to search for a contact by name or email
    const onContactSearch = (e: any) => {
        if (e.target.value.length === 0) {
            setContactsFiltered(recentContacts);
            return;
        }
        const filtered = contacts.filter((contact) => {
            return (contact as any).first_name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        if (filtered.length === 0) {
            setContactsFiltered(filtered);
        } else {
            const isWritingEmail = e.target.value.includes('@');
            isWritingEmail && filterContactsByEmail(e.target.value);
            !isWritingEmail && filterContactsByName(e.target.value);
        }
    }

    useEffect(() => {
        setContactFallback(contact as UserData);
    }, [contact])

    const filterContactsByEmail = (email: string) => {
        const filtered = contacts.filter((contact) => {
            return contact.email.includes(email);
        });
        setContactsFiltered(filtered);
    }

    const filterContactsByName = (name: string) => {
        const filtered = contacts.filter((contact) => {
            return (contact as any).first_name.includes(name);
        });
        setContactsFiltered(filtered);
    }

    useEffect(() => {
        getAllRecentContacts();
        getUsersList()
            .then((users) => {
                setContacts(users);
            })
            .catch((error) => {
                console.log('Error in getUsersList, ', error);
            })
    }, []);

    const getAllRecentContacts = async () => {
        const users = await getMostRecentUsers(10, null)
        let recentContacts: UserData[] = [];
        if (!users) return;
        for (let i = 0; i < users.length; i++) {
            const address = users[i];
            const contact = await addContactToRecentContacts(address);
            if (contact) {
                recentContacts.push(contact);
            }
        }
        setRecentContacts(recentContacts);
        setContactsFiltered(recentContacts);
    }

    const addContactToRecentContacts = async (address: string): Promise<UserData | undefined> => {
        const user = await getUserNameByAddress(address)
        if (user) {
            const { profilePicture, first_name, email, last_name } = user;
            return ({
                id: address,
                name: first_name,
                profilePicture: profilePicture,
                email: email,
                realm: 'user',
                last_name: last_name
            });
        }
        return undefined;
    }

    const onClickContact = (contact: UserData) => {
        setContact(contact);
        setContactsFiltered([contact]);
    }


    return (
        <>
            <Grid item xs={12}>
                <SearchBar onChange={onContactSearch} text={'Ricerca contatto'} />
            </Grid>
            <Grid item xs={12}>
                <ContactList contacts={contactsFiltered} onClick={onClickContact} />
            </Grid>
        </>
    )
}