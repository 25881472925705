import config from "../config";
import { Item, ItemStats, ItemStatsResponse, UploadItemInformation } from "../types/libofthings.type";
import * as axiosSOT from "../utilities/backend/axios-metadata";
import { AxiosResponse } from "axios";
import { ethers, logger, utils, Wallet } from "ethers";

type Preorder = {
    data: {
        signature: string,
        start: number,
        end: number,
        itemID: number,
        borrower: string,
        hash: string
    }
}

type User = {
    id: string,
    fullname: string
}

type PreorderResponseData = {
    ok: boolean,
    message: string,
    preorder: Preorder,
    user: User,
    timestamp: number,
}
export const getLibOfThingsInstance = (ethersInstance: Wallet) => {
    const abi = new utils.Interface(config.smartContracts.ITM_MKP_ABI)
    return new ethers.Contract(
        config.smartContracts.ITM_MKP_ADDRESS,
        abi,
        ethersInstance
    )
}

const getNftTemplateInstance = (ethersInstance: Wallet, address: string) => {
    const abi_template = new utils.Interface(config.smartContracts.NFT_TMPLT_ABI)

    return new ethers.Contract(
        address,
        abi_template,
        ethersInstance
    )
}


export const getAvailableCategories = async (
    realm: string,
): Promise<string[]> => {
    if (realm === 'chgarden') return ['Giardinaggio'];
    return ['ELETTRODOMESTICI/CUCINA', 'ARREDI', 'TRASPORTO', 'GIOCHI', 'ARTE MUSICA CULTURA', 'FAI DA TE', 'ABBIGLIAMENTO', 'PIANTE', 'ARTIGIANATO', 'ALTRO'];
}

export const getItemInfoByID = async (
    itemID: number,
): Promise<Item | undefined> => {
    let item;
    try {
        const q = `${config.network.metadata.url}lot/items/${itemID}?itemMarketplaceAddress=${config.smartContracts.ITM_MKP_ADDRESS}`;
        const res = await axiosSOT.default.get(q);

        if (res.status === 200) {
            item = res.data.item;
        } else {
            throw new Error(`Error while fetching item with ID: ${itemID} from the backend. Status code: ${res.status})`);
        }

    } catch (error) {
        console.error(error);
    }
    return item;
}

export const uploadItem = async (
    ethers: Wallet,
    itemData: UploadItemInformation,
    addressNftCollection: string,
    from: string
) => {
    try {
        const libOfThingsInstance = getLibOfThingsInstance(ethers);
        const nftTemplateInstance = getNftTemplateInstance(ethers, addressNftCollection);
        //Approve the library of things

        let trx = await nftTemplateInstance.approve(config.smartContracts.ITM_MKP_ADDRESS, itemData.idInCollection)
        trx = await trx.wait();
        console.log(`Trx approval`);
        console.log(trx);
        //Upload the item
        console.log(libOfThingsInstance);
        console.log(itemData);
        let trx2 = await libOfThingsInstance.registerItem(itemData)
        trx2 = await trx2.wait();
        console.log(`Transaction for the upload of the item onto the ItemMarketplace`);
        console.log(trx2);
        return trx2;
    } catch (error) {
        console.log(error);
    }
}
export const setRequireSuperision = async (ethers: Wallet, itemID: number, requireSupervision: boolean) => {
    console.log(`Setting supervision for item with id: ${itemID} with value: ${requireSupervision}`);
    try {
        const libOfThingsInstance = getLibOfThingsInstance(ethers);
        let trx = await libOfThingsInstance.setRequireSupervision(itemID, requireSupervision);
        const receipt = await trx.wait();
        console.log(receipt);
    } catch (error) {
        console.log(error);
    }
}
export const wasRewardedAPI = async (ethers: Wallet, from: string) => {
    const libOfThingsInstance = getLibOfThingsInstance(ethers);
    const wasRewarded = await libOfThingsInstance.rewardClaimed(from);
    return wasRewarded;
}
export const rewardUserAPI = async (ethers: Wallet, from: string, erc20TokenAddress: string) => {
    const libOfThingsInstance = getLibOfThingsInstance(ethers);
    let trx = await libOfThingsInstance.claimReward(erc20TokenAddress);
    trx = await trx.wait();
    console.log(trx);
    return trx;
}

export const getItemsOfLoggedUser = async (
    ethers: Wallet,
    address: string
) => {
    const items: Item[] = [];
    const q = `${config.network.metadata.url}lot/${address}/myItems?first=100&skip=0`
    const res = await axiosSOT.default.get(q);
    if (res.data != null && res.data.items != null) {
        console.log(res.data.items);
        res.data.items.map((item: any) => {
            items.push({
                itemID: item.itemID,
                idInCollection: item.idInCollection,
                price: item.price,
                caution: item.caution,
                owner: item.owner,
                name: item.name,
                collectionSymbol: item.collectionSymbol,
                paymentToken: item.paymentToken,
                option: item.option,
                dateOfUpload: item.dateOfUpload,
                category: item.category,
                hidden: item.hidden,
                requireSupervision: item.requireSupervision,
                numberOfTimesRequested: item.numberOfTimesRequested
            })
        })
    }

    return items;
}

export const getNewArrivalsAPI = async (nPage: number, first: number) => {
    const items: Item[] = [];
    const q = `${config.network.metadata.url}lot/items/newArrivals?skip=${nPage * first}&first=${first}`;

    const res = await axiosSOT.default.get(q);
    if (res.data != null && res.data.items != null) {
        res.data.items.map((item: any) => {
            items.push({
                itemID: item.itemID,
                idInCollection: item.idInCollection,
                price: item.price,
                caution: item.caution,
                owner: item.owner,
                name: item.name,
                collectionSymbol: item.collectionSymbol,
                paymentToken: item.paymentToken,
                option: item.option,
                dateOfUpload: item.dateOfUpload,
                category: item.category,
                hidden: item.hidden,
                requireSupervision: item.requireSupervision,
                numberOfTimesRequested: item.numberOfTimesRequested
            })
        })
    }
    return items;
}

export const generateHashForPreorder = async (
    itemID: number,
    startDate: Date,
    endDate: Date,
    address: string
): Promise<{ hash: string, signature: string }> => {
    let hash = '', signature = '';
    const startDateUnix = Math.floor(startDate.getTime() / 1000);
    const endDateUnix = Math.floor(endDate.getTime() / 1000);
    const q = `${config.network.metadata.url}lot/preorders/generateHash?id=${itemID}`;
    const res: AxiosResponse<PreorderResponseData> = await axiosSOT.default.post(q, {
        startDate: startDateUnix,
        endDate: endDateUnix,
        address: address
    }, {
        headers: {
            "Authorization": localStorage.getItem("token")
        }
    });
    if (res.status === 200) {
        signature = res.data.preorder.data.signature;
        hash = res.data.preorder.data.hash;
    } else {
        throw new Error(res.data.message);
    }

    return { hash, signature };
}

export const getItemStats = async (itemID: string) => {
    let itemStats: ItemStats = { history: null, numberOfPreorders: 0 };

    const q = `${config.network.metadata.url}lot/stats/${itemID}`;
    const res: AxiosResponse<ItemStatsResponse> = await axiosSOT.default.get(q, {
        headers: {
            "Authorization": localStorage.getItem("token")
        }
    });
    if (res != null && res.data != null) {
        if (res.status === 200) {
            itemStats.history = res.data.history;
            if (res.data.history != null)
                itemStats.numberOfPreorders = res.data.history.length;
        } else {
            throw new Error(res.data.message);
        }
    } else {
        if (res.status != null) {
            throw new Error(`Response is null: status: ${res.status}`);
        } else {
            throw new Error(`Response is null, status is null too`);
        }
    }

    return itemStats;
}
export const getTokenFactoryFromLibraryOfThings = async (ethers: Wallet, from: string) => {
    const libOfThingsInstance = getLibOfThingsInstance(ethers);
    const tokenFactoryAddress = await libOfThingsInstance.getERC20FactoryAddress();
    console.log(`tokenFactoryAddress: ${tokenFactoryAddress}`);
    return tokenFactoryAddress;
}
